import { render, staticRenderFns } from "./form_add.vue?vue&type=template&id=61626696&scoped=true&"
import script from "./form_add.vue?vue&type=script&lang=js&"
export * from "./form_add.vue?vue&type=script&lang=js&"
import style0 from "./form_add.vue?vue&type=style&index=0&id=61626696&lang=less&scoped=true&"
import style1 from "./form_add.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61626696",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('61626696')) {
      api.createRecord('61626696', component.options)
    } else {
      api.reload('61626696', component.options)
    }
    module.hot.accept("./form_add.vue?vue&type=template&id=61626696&scoped=true&", function () {
      api.rerender('61626696', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "found/views/sfa/views/visit_manage/visit_plan_formulate/form/components/form_add.vue"
export default component.exports